import React from 'react';
import { Grid, Icon, Image } from 'semantic-ui-react'

export default class ToastContainer extends React.Component {
    render(){
        return(
            <Grid columns={2} className='toast-container'>
              <Grid.Row>
                  <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
                    {this.props.iconType !== 'image' && (
                        <Icon size='big' name={this.props.icon}/>
                    )}
                    {this.props.iconType === 'image' && (
                        <Image rounded src={this.props.src}/>
                    )}
                  </Grid.Column>
                  <Grid.Column verticalAlign='middle' width={14}>
                      <p>{this.props.message}</p>
                  </Grid.Column>
              </Grid.Row>
            </Grid>
        )
    }
}
