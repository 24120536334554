import {
    SET_APP_VERSION, TOOGLE_SPOTIFY_MODAL, SET_SPOTIFY_TOKEN, CLEAR_TOKENS,
    SET_SPOTIFY_ARTIST_FOLLOW, SPOTIFY_FOLLOWING_INTENTION, ACCOUNT_RECENTLY_CREATED
} from "../constants/action-types";

const initialState = {
    modalOpenedBy: '',
    is_modal_open: false,
    access_token: null,
    expiration_time: null,
    is_following_artist: false,
    is_devices_modal_open: false,
    is_following_intention: false,
    is_recently_created: false,
    next_update: null,
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case TOOGLE_SPOTIFY_MODAL:
            return Object.assign({}, state, {
                is_modal_open: !state.is_modal_open,
                modalOpenedBy: action.payload
            });
        case SET_SPOTIFY_TOKEN:
            return Object.assign({}, state, {
                access_token: action.payload.access_token,
                expiration_time: action.payload.expiration_time
            });
        case SET_SPOTIFY_ARTIST_FOLLOW:
            return Object.assign({}, state, {
                is_following_artist: action.payload,
                next_update: Math.round(
                    new Date(new Date().getTime() +
                    (process.env.REACT_APP_SPOTIFY_UPDATE * 60 * 1000))/1000
                )
            });
        case SPOTIFY_FOLLOWING_INTENTION:
            return Object.assign({}, state, { is_following_intention: action.payload });
        case ACCOUNT_RECENTLY_CREATED:
            return Object.assign({}, state, { is_recently_created: action.payload });
        case SET_APP_VERSION:
            return initialState;
        case CLEAR_TOKENS:
            return Object.assign({}, state, { access_token: null, expiration_time: null});
        default:
            return state
    }
};

export default rootReducer;
