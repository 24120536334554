import React from 'react';
import Main from '../../../layouts/Main';
import DataTable from '../../../components/Datatable';
import { Header, Grid } from 'semantic-ui-react';

export default class Index extends React.Component {
    render() {
        return (
            <Main>
                <Grid columns={1} divided stackable>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign='center'>
                            <Header as='h1' inverted>Users</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="datatable-container">
                        <Grid.Column width={16} textAlign='center'>
                            <DataTable
                                actions
                                resource="users"
                                columns={[
                                    {data: "fullname"},
                                    {data: "email"},
                                    {data: "created_at"}
                                ]}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Main>
        )
    }
}
