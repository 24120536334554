import React from 'react';
import { Dropdown } from 'semantic-ui-react'

function Size(props) {
    const options = [
      { key: 1, text: 'XS', value: 1 },
      { key: 2, text: 'S', value: 2 },
      { key: 3, text: 'M', value: 3 },
      { key: 4, text: 'L', value: 3 },
      { key: 5, text: 'XL', value: 3 },
    ]
    return <Dropdown
            selection
            placeholder={props.placeholder ? props.placeholder : 'Size'}
            options={options}
            compact={props.compact}
            fluid={props.fluid}/>
}

export default Size;
