import React, {useState} from 'react';
import { Accordion, Icon, List } from 'semantic-ui-react';

function DetailProductInformation(props) {
    const [activeIndex, setIndex] = useState(null);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index
        setIndex(newIndex)
    }
    return (
        <Accordion styled className="detail-product-information">
            <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
                <Icon name='info circle'/>Product Info<Icon name='dropdown'/>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0} className="details">
                <p>Composition:</p>
                <List bulleted>
                    <List.Item>Elastic rib: Cotton 95%, Elastane 5%</List.Item>
                    <List.Item>Lining: Cotton 100%</List.Item>
                    <List.Item>Cotton 80%, Polyester 20%</List.Item>
                </List>
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 1} index={1} onClick={handleClick}>
                <Icon name='shipping fast'/>Shipping Option<Icon name='dropdown'/>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1} className="shipping">
                <List divided relaxed>
                    <List.Item>
                        <List.Content floated='right' className="price">
                            $26.50
                        </List.Content>
                        <List.Content className="method">
                            <List.Header as='a'>Courier</List.Header>
                            <List.Description as='a'>2 - 4 days</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content floated='right' className="price">
                            $10.00
                        </List.Content>
                        <List.Content className="method">
                            <List.Header as='a'>Local shipping</List.Header>
                            <List.Description as='a'>Up to one week</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content floated='right' className="price">
                            $33.85
                        </List.Content>
                        <List.Content className="method">
                            <List.Header as='a'>Flat rate</List.Header>
                            <List.Description as='a'>5 - 7 days</List.Description>
                        </List.Content>
                    </List.Item>
                </List>
            </Accordion.Content>
        </Accordion>
    )
}

export default DetailProductInformation;
