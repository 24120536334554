import React from 'react';
import api from '../../../api';
import { toast } from 'react-toastify';
import Main from '../../../layouts/Main';
import DataTable from '../../../components/Datatable';
import { Header, Grid, Table, Checkbox } from 'semantic-ui-react';

export default class Index extends React.Component {
    handleMarketingOnChange = (event, { checked }) => {
        let id = event.target.parentElement.dataset.id;
        api({
            method: checked ? "post" : "delete",
            url:`${process.env.REACT_APP_BACKEND_API_URL}/admin/subscriptions/${id}/subscribe`
        }).then(data => toast.success(`Subscription ${checked ? 'registered' : 'removed from registry'} sucessfully`, { position: "top-right" }))
        .catch(err => {
            console.log(err);
            toast.sucess('An unexpected error happened while subscribing this registry', { position: "top-right" })
        });
    }
    render() {
        return (
            <Main>
                <Grid columns={1} divided stackable>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign='center'>
                            <Header as='h1' inverted>Subscriptions</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign='center'>
                            <DataTable
                                resource="subscriptions"
                                actions={['delete']}
                                columns={[
                                {"data":"fullname"},
                                {"data":"email"},
                                {"data":"marketing_registered", render:(value, key, row) => {
                                    this[`${row.id}`] = React.createRef()
                                    return (
                                        <Table.Cell key={row.id}>
                                            <Checkbox
                                                toggle
                                                data-id={row.id}
                                                defaultChecked={value === 1}
                                                onChange={this.handleMarketingOnChange}
                                                ref={this[`${row.id}`]}/>
                                        </Table.Cell>
                                    );
                                }},
                                {"data":"created_at"}
                            ]}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Main>
        )
    }
}
