import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Menu, Sidebar, Icon } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom'

class HeaderSidebar extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };
    render() {
        return (
            <Sidebar as={Menu} inverted vertical
                size='massive'
                icon='labeled'
                direction='top'
                animation='overlay'
                className='mobile-sidebar'
                visible={this.props.isDimmed}>
                <Menu.Item
                    position='right'
                    borderless='true'
                    fitted='horizontally'
                    className='menu-close'
                    onClick={this.props.dimmedHandler}>
                    <Icon name='close' />
                </Menu.Item>
                <Menu.Item
                    name='Home'
                    as={Link} to='/'
                    active={this.props.location.pathname === '/'}/>
                <Menu.Item
                    name='recordings'
                    as={Link} to='/recordings'
                    active={this.props.location.pathname === '/recordings'}/>
            </Sidebar>
        )
    }
}
export default withRouter(HeaderSidebar)
