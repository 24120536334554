import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default class BandIcon extends Component{
    render(){
        return (
            <Menu.Item className="band-name" as={Link} to='/'>
                <span className="channel-split-wrapper">
                    <span className="channel-split-static">
                        <span className="trotm">Phonobox</span>
                    </span>
                    <span className="channel-split channel-split-red">
                        <span className="trotm">Phonobox</span>
                    </span>
                    <span className="channel-split channel-split-green">
                        <span className="trotm">Phonobox</span>
                    </span>
                    <span className="channel-split channel-split-blue">
                        <span className="trotm">Phonobox</span>
                    </span>
                </span>
            </Menu.Item>
        )
    }
}
