import React from 'react';
import Main from '../../../layouts/Main';
import AdminForm from '../../../components/form/Main';

export default class Item extends React.Component {
    render() {
        return (
            <Main>
                <AdminForm
                    submit
                    resource="users"
                    fields={[
                        {"type": "text", "title": "fullname", "data": "fullname"},
                        {"type": "text", "title": "email", "data": "email"}
                    ]}>
                </AdminForm>
            </Main>
        )
    }
}
