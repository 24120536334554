import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import ProductDescription from './Description';
import 'react-awesome-slider/dist/styles.css';
import '../../../sass/shop/product-carrousel.scss';
import { Header, Grid, Segment, Image } from 'semantic-ui-react';

class ProductDetail extends React.Component {
    render() {
        return (
            <Grid columns={3} divided>
                <Grid.Row>
                    <Grid.Column width={16} textAlign='left'>
                        <Header as='h1' inverted>{this.props.title}</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="product-detail">
                    <Grid.Column width={16} textAlign='left'>
                        <Segment placeholder>
                            <Grid columns={3}>
                                <Grid.Row>
                                    <Grid.Column className="bullets preview" width={2}>
                                        <Image src='/store/products/detail/01.jpg' fluid rounded/>
                                        <Image src='/store/products/detail/02.jpg' fluid rounded/>
                                        <Image src='/store/products/detail/03.jpg' fluid rounded/>
                                    </Grid.Column>
                                    <Grid.Column className="slider-container" width={7}>
                                        <AwesomeSlider bullets={false} className="product-slider">
                                            <div data-src="/store/products/detail/01.jpg"/>
                                            <div data-src="/store/products/detail/02.jpg"/>
                                            <div data-src="/store/products/detail/03.jpg"/>
                                        </AwesomeSlider>
                                    </Grid.Column>
                                    <Grid.Column className="description" width={7}>
                                        <ProductDescription />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default ProductDetail;
