import {
    TOOGLE_SPOTIFY_MODAL, SET_SPOTIFY_TOKEN, SET_SPOTIFY_ARTIST_FOLLOW,
    UPDATE_SPOTIFY_ALBUM, SET_SPOTIFY_DEVICES, SET_SPOTIFY_PLAYING_TRACK, STOP_SPOTIFY_TRACK,
    SET_APP_VERSION, SET_APP_FINGERPRINT, SET_APP_DEVICE_TOKEN, SET_APP_COOKIES_DISCLAIMER,
    TOOGLE_SPOTIFY_DEVICES_MODAL, SPOTIFY_FOLLOWING_INTENTION, CLEAR_TOKENS,
    CLEAR_SPOTIFY_PLAYER, SET_APP_TOKEN_EXPIRATION, LOGOUT, SET_USER, CLEAR_USER
} from "../constants/action-types";

export function clearTokens(payload) {
    return { type: CLEAR_TOKENS, payload }
};

export function toogleSpotifyModal(payload) {
    return { type: TOOGLE_SPOTIFY_MODAL, payload }
};

export function setSpotifyToken(payload) {
    return { type: SET_SPOTIFY_TOKEN, payload}
}

export function setSpotifyArtistFollow(payload) {
    return { type: SET_SPOTIFY_ARTIST_FOLLOW, payload}
}

export function updateSpotifyAlbum(payload) {
    return { type: UPDATE_SPOTIFY_ALBUM, payload}
}

export function setSpotifyDevices(payload) {
    return { type: SET_SPOTIFY_DEVICES, payload}
}

export function setSpotifyPlayingTrack(payload) {
    return {type: SET_SPOTIFY_PLAYING_TRACK, payload}
}

export function clearSpotifyPlayer(payload) {
    return {type: CLEAR_SPOTIFY_PLAYER, payload}
}

export function stopSpotifyTrack(payload) {
    return {type: STOP_SPOTIFY_TRACK, payload}
}

export function setAppVersion(payload) {
    return {type: SET_APP_VERSION, payload}
}

export function setAppFingerprint(payload) {
    return {type: SET_APP_FINGERPRINT, payload}
}

export function setAppDeviceToken(payload) {
    return {type: SET_APP_DEVICE_TOKEN, payload}
}

export function setAppCookiesDisclaimer(payload) {
    return {type: SET_APP_COOKIES_DISCLAIMER, payload}
}

export function toogleSpotifyDevicesModal(payload) {
    return {type: TOOGLE_SPOTIFY_DEVICES_MODAL, payload}
}

export function setSpotifyFollowIntention(payload) {
    return {type: SPOTIFY_FOLLOWING_INTENTION, payload}
}

export function setTokenExpirationTime(payload) {
    return {type: SET_APP_TOKEN_EXPIRATION, payload}
}
export function setUser(payload) {
    return {type: SET_USER, payload}
}
export function clearUser(payload) {
    return {type: CLEAR_USER, payload}
}

export function logout() {
    return {type: LOGOUT}
}
