import {
    SET_SPOTIFY_PLAYING_TRACK, STOP_SPOTIFY_TRACK, SET_SPOTIFY_DEVICES, SET_APP_VERSION,
    TOOGLE_SPOTIFY_DEVICES_MODAL, CLEAR_SPOTIFY_PLAYER
} from "../constants/action-types";

const initialState = {
    devices: {
        items:[],
        next_update: null,
    },
    is_devices_modal_open: false,
    pending_uri: null,
    is_playing: false,
    progress_ms: 0,
    actual_track: {},
    next_update: null,
};

export default function playerReducer(state = initialState, action) {
    switch (action.type) {
        case STOP_SPOTIFY_TRACK:
            return Object.assign({}, state, {
                is_playing: false,
                progress_ms: 0,
                actual_track: undefined
            })
        case SET_SPOTIFY_PLAYING_TRACK:
            return Object.assign({}, state, {
                is_playing: action.payload.is_playing,
                progress_ms: action.payload.progress_ms,
                actual_track: action.payload.item,
                next_update: Math.round(
                    new Date(new Date().getTime() +
                    (process.env.REACT_APP_SPOTIFY_UPDATE * 60 * 1000))/1000
                )
            });
        case SET_SPOTIFY_DEVICES:
            return Object.assign({}, state, {
                devices:{
                    items: action.payload,
                    next_update: Math.round(
                        new Date(new Date().getTime() +
                        (process.env.REACT_APP_SPOTIFY_UPDATE * 60 * 1000))/1000
                    )
                }
            });
        case TOOGLE_SPOTIFY_DEVICES_MODAL:
            return Object.assign({}, state, {
                pending_uri: action.payload,
                is_devices_modal_open: !state.is_devices_modal_open
            });
        case CLEAR_SPOTIFY_PLAYER:
            return Object.assign({}, state, {
                //is_paused: false,
                is_playing: false,
                progress_ms: 0,
                actual_track: undefined
            });
        case SET_APP_VERSION:
            return initialState;
        default:
            return state
    }
}
