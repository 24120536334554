import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Container, Button, Icon, Modal, Image } from 'semantic-ui-react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import SpotifyWebApi from 'spotify-web-api-node';
import {
    toogleSpotifyModal, updateSpotifyAlbum
} from "../../redux/actions/index";
import api from '../../api';

const spotifyClient = new SpotifyWebApi({
    clientId: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
    redirectUri: process.env.REACT_APP_SPOTIFY_REDIRECT_URI
});

const scopes = [
    'user-read-email', 'user-follow-read', 'user-follow-modify', 'user-library-read',
    'user-library-modify', 'user-read-currently-playing', 'user-modify-playback-state',
    'user-read-playback-state', 'user-top-read'
];

class LoginModal extends Component {
    state = { isLoading: false }
    componentDidUpdate(prevProps){
        if((this.props.spotify.access_token !== null && prevProps.spotify.expiration_time !== this.props.spotify.expiration_time)){
            this.updateAlbumsData()
        }
        if(this.props.spotify.is_modal_open === true) {
            ReactPixel.trackCustom('Spotify', { action:'Modal Opened', content_category: this.props.spotify.modalOpenedBy });
            ReactGA.event({ category: 'Spotify', action: 'Modal Opened', label: this.props.spotify.modalOpenedBy});
        }
    }

    updateAlbumsData = () => {
        this.props.albums.forEach(element => {
            if(element.id){
                api.get(`me/albums/${element.id}`).then(
                    response => this.props.updateSpotifyAlbum(response.data),
                    err => console.error(err)
                )
            }
        });
    }
    handleClose = () => {
        this.props.toogleSpotifyModal();
        ReactGA.event({ category: 'Spotify', action: 'Login Canceled'});
        ReactPixel.trackCustom('Spotify', {action: 'Login Canceled'});
    }
    handleClicked = () => {
        this.setState({ isLoading: true });
        ReactGA.event({ category: 'Spotify', action: 'Login Accepted'});
        ReactPixel.trackCustom('Spotify', {action: 'Login Accepted'});
    }
    render() {
        return (
            <Modal
                basic
                size='small'
                dimmer='blurring'
                className='spotify-modal'
                onClose={this.handleClose}
                open={this.props.spotify.is_modal_open}>
                <Modal.Content>
                    <Container textAlign='center' className='avatar-container'>
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT}/images/avatar.jpg`} size='tiny' circular inline/>
                        <Container text content="..." />
                        <Icon size='huge' name='spotify' />
                    </Container>
                    <p>We've worked in a really cool integration with Spotify! Would you like to connect your account now?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleClose}>
                        <Icon name='remove' /> Maybe Another time
                    </Button>
                    <Button as="a" color='green'
                        onClick={this.handleClicked}
                        loading={this.state.isLoading}
                        href={spotifyClient.createAuthorizeURL(scopes, JSON.stringify({ section: this.props.section }))}>
                        <Icon name='checkmark' /> Yes!
                    </Button>
                </Modal.Actions>
                <Modal.Description className='privacy-container'>
                    <Link to='/privacy-policy'>*If you have any concern about how we treat your data you can check our privacy policy</Link>
                </Modal.Description>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
  return {
      spotify: state.spotify,
      albums: state.recordings.albums
  };
};
const mapDispatchToProps = dispatch => {
  return {
      toogleSpotifyModal: () => dispatch(toogleSpotifyModal()),
      updateSpotifyAlbum: album => dispatch(updateSpotifyAlbum(album)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
