import React from 'react';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import ToastContainer from '../ToastContainer';
import api from '../../api';
import {
    toogleSpotifyModal, setSpotifyPlayingTrack, stopSpotifyTrack,
    toogleSpotifyDevicesModal, setSpotifyDevices
} from "../../redux/actions/index";

class PlayButton extends React.Component {
    state = { is_loading: false }

    componentDidMount(){ this.componentUpdateData() }
    componentDidUpdate(prevProps){
        if(!prevProps.player.is_playing && this.props.player.is_playing){
            toast.info(<ToastContainer
                    iconType='image'
                    src={this.props.player.actual_track.album.images[2].url}
                    message={`Now you are Listening to ${this.props.player.actual_track.name}`}/>,{ className: 'violet-background' })
        }
        if(this.props.pauseable && prevProps.player.is_playing && !this.props.player.is_playing){
            toast.warn(<ToastContainer
                    iconType='image'
                    src={this.props.player.actual_track.album.images[2].url}
                    message={`${this.props.player.actual_track.name} was paused`}/>)
        }
        this.componentUpdateData();
    }
    componentUpdateData = (prevProps) => {
        if(this.props.hasValidToken && this.props.player.devices.next_update < Math.round(new Date().getTime()/1000)){
            api.get(`me/player/devices`).then(
                response => this.props.setSpotifyDevices(response.data.devices)
            );
        }
        if(this.props.hasValidToken && this.props.player.is_playing && this.props.player.next_update < Math.round(new Date().getTime()/1000)){
            this.getCurrentPlayingTrack();
        }
    }
    getCurrentPlayingTrack = () => {
        api.get(`me/player/currently-playing`).then(
            response => {
                if(response.status === 200){
                    this.props.setSpotifyPlayingTrack(response.data);
                }else {
                    this.props.stopSpotifyTrack();
                }
            },
            err => console.error(err)
        ).then(setTimeout(() => { this.setState({ is_loading: false }) }, process.env.REACT_APP_SPOTIFY_TIMEOUT));
    }
    getSpotifyDevices = () => {
        api.get(`me/player/devices`).then(
            response => {
                this.props.setSpotifyDevices(response.data.devices);
                if(response.data.devices.length === 0) {
                    toast.error(<ToastContainer icon='spotify' message='You Need to Open Spotify in your phone or your computer first.' />);
                } else {
                    this.props.toogleSpotifyDevicesModal(this.props.uri);
                }
            }
        ).then(this.setState({ is_loading: false }));
    }
    handlePlayClick = () => {
        if(!this.props.hasValidToken){
            this.props.toogleSpotifyModal('Play Button');
        } else {
             this.setState({ is_loading: true });
             if(this.props.pauseable === true && this.props.player.is_playing){
                 api.put("me/player/pause").then(
                     data => this.getCurrentPlayingTrack(),
                     err => {
                         console.error(err);
                         toast.error(<ToastContainer icon='spotify' message='Something wrong happend while connection to Spotify, please try again later.' />);
                     }
                 );
             } else {
                if(!this.props.pauseable){
                    this.props.stopSpotifyTrack();
                }
                api.put("me/player/play",{
                    context_uri: this.props.uri
                }).then(
                    response => this.getCurrentPlayingTrack(),
                    error => {
                        if(error.response.status === 404){
                            this.getSpotifyDevices();
                        } else {
                            toast.error(<ToastContainer icon='spotify' message='Something wrong happend while connection to Spotify, please try again later.' />);
                            this.setState({ is_loading: false })
                        }
                    }
                )
             }
        }
    }
    render(){
        return (
            <Button
                className='play-button'
                size={this.props.size}
                icon={this.props.icon}
                loading={this.state.is_loading}
                circular={this.props.circular}
                onClick={this.handlePlayClick}
                onMouseEnter={this.props.activateHover}
                onMouseLeave={this.props.deactivateHover}
                >
                {this.props.text && (
                    <FormattedMessage
                        id={`player.${this.props.player.is_playing ?  'pause' : 'play'}`}
                        description="Player Play Button Label"/>
                )}
            </Button>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        player: state.player,
        spotify: state.spotify,
        hasValidToken: state.app.cookie_expiration_time != null && state.app.cookie_expiration_time > Math.round(new Date().getTime()/1000)
    };
};
export default connect(mapStateToProps, {
    toogleSpotifyModal, setSpotifyPlayingTrack, stopSpotifyTrack,
    toogleSpotifyDevicesModal, setSpotifyDevices
})(PlayButton);
