import React from 'react';
import ProductPrice from './tools/Price';
import ColorPicker from "./tools/ColorPicker";
import Size from "./tools/Size";
import Quantity from "./tools/Quantity";
import DetailProductInformation from "./Information";
import { Grid,Segment, Container, Label, Icon, Button } from 'semantic-ui-react';

function ProductDescription(props) {
    return (
        <Segment>
            <Label color='green' ribbon='right'>
              <Icon name='check circle outline' />Product Available
            </Label>
            <Container className="price">
                <ProductPrice price="21.90" size="big"/>
            </Container>
            <Container className="text-colors">
                <p><b>Color: </b>Red/Dark blue/White</p>
            </Container>
            <Container className="color-picker hide">
                <ColorPicker />
            </Container>
            <Container className="size">
                <p>Size:</p>
                <Size fluid placeholder="Select Your Size"/>
            </Container>
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Quantity fluid/>
                        </Grid.Column>
                        <Grid.Column width={11}>
                            <Button icon='cart' content="I'm Interested" color="red" fluid/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <Container>
                <DetailProductInformation />
            </Container>
        </Segment>
    )
}

export default ProductDescription;
