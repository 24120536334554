import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Icon } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import {
    toogleSpotifyModal, setSpotifyArtistFollow, setSpotifyFollowIntention
} from "../../redux/actions/index";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import api from '../../api';

class FollowButton extends Component {
    state = { is_loading: false }

    componentDidMount(){ this.getIsUserFollowing() }
    componentDidUpdate(prevProps){ this.getIsUserFollowing() }

    getIsUserFollowing = () => {
        if(this.props.hasValidToken && this.props.spotify.next_update < Math.round(new Date().getTime()/1000)){
            api.get(`me/following/${process.env.REACT_APP_SPOTIFY_ARTIST_ID}`).then(
                response => this.props.setSpotifyArtistFollow(response.data.following)
            ).then(() => {
                if(!this.props.isArtistFollowing && this.props.spotify.is_following_intention) {
                    //Follow Up Intention
                    this.setState({ is_loading: true });
                    api.put(`me/following/${process.env.REACT_APP_SPOTIFY_ARTIST_ID}`).then(
                        response => this.props.setSpotifyArtistFollow(response.data.following)
                    ).then(() => {
                        ReactGA.event({ category: 'Spotify', action: 'New Follower'});
                        ReactPixel.trackCustom('Spotify', {action: 'New Follower'});
                        this.props.setSpotifyFollowIntention(false);
                    }).finally(() => this.setState({ is_loading: false }));
                }
            });
        }
    }
    handleFollowing = () => {
        if(!this.props.hasValidToken){
            this.props.toogleSpotifyModal('Follow Button');
        } else {
            this.setState({ is_loading: true });
            if(this.props.is_following_artist) {
                api.delete(`me/following/${process.env.REACT_APP_SPOTIFY_ARTIST_ID}`).then(
                    response => this.props.setSpotifyArtistFollow(response.data.following)
                ).finally(() => this.setState({ is_loading: false }))
            } else {
                api.put(`me/following/${process.env.REACT_APP_SPOTIFY_ARTIST_ID}`).then(
                    response => this.props.setSpotifyArtistFollow(response.data.following)
                ).finally(() => this.setState({ is_loading: false }))
            }
        }
    }
    buttonClassName = () => {
        let className = isMobile ? 'mobile' : '';
        return className += ' ' + (this.props.is_following_artist ? 'following' : 'follow');
    }
    render() {
        return(
            <div>
                <Button
                    onClick={this.handleFollowing}
                    loading={this.state.is_loading}
                    className={`spotify ${this.buttonClassName()}`}>
                  <Icon name='spotify' />
                </Button>
            </div>
        )
    }
}

const mapStateToProps = state => {
  return {
      spotify: state.spotify,
      is_following_artist: state.spotify.is_following_artist,
      hasValidToken: state.app.cookie_expiration_time != null && state.app.cookie_expiration_time > Math.round(new Date().getTime()/1000)
  };
};

export default connect(mapStateToProps, {
    toogleSpotifyModal, setSpotifyArtistFollow, setSpotifyFollowIntention
})(FollowButton);
