import { createStore, combineReducers } from "redux";
import app from "../reducers/appReducer";
import player from "../reducers/playerReducer";
import spotify from "../reducers/spotifyReducer";
import recordings from "../reducers/recordingsReducer";


function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch(e) {
        console.error(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state');
        if(serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch(e) {
        console.error(e);
        return undefined
    }
}

const persistedState = loadFromLocalStorage();

const rootReducer = combineReducers({app, spotify, recordings, player})

const store = createStore(rootReducer, persistedState);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
