import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setAppCookiesDisclaimer } from "../redux/actions/index";
import { Portal, Segment, Header, Button, Icon, Grid } from 'semantic-ui-react';

class CookiesMessage extends Component {
    state = { open: true }
    handleClose = () => this.props.setAppCookiesDisclaimer(false)
    render() {
        return (
            <Portal onClose={this.handleClose} open={ this.props.app.show_cookies_disclaimer }>
                <Segment
                    inverted
                    color='grey'
                    className='cookies-container'>
                    <Header>
                        <Icon name='warning circle' />
                        <Header.Content>Cookies Everywhere!</Header.Content>
                    </Header>
                    <Grid stackable>
                        <Grid.Column width={13} verticalAlign='middle' className='disclaimer-content'>
                            <p>Phonobox uses cookies to provide a great expirience. By using our website, you agree to the use of cookies, for more information check our
                                <Link to='/privacy-policy' className='text-link' target='_blank'> Cookie Policy.</Link>
                            </p>
                        </Grid.Column>
                        <Grid.Column width={3} verticalAlign='middle' className='button-container'>
                            <Button
                                content='I Agree'
                                negative
                                onClick={this.handleClose}/>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Portal>
        )
    }
}

const mapStateToProps = state => {
  return {
      app: state.app
  };
};

export default connect(mapStateToProps, { setAppCookiesDisclaimer })(CookiesMessage);
