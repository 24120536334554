import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from 'semantic-ui-react';
import { toogleSpotifyModal, updateSpotifyAlbum } from "../../redux/actions/index";
import api from '../../api';

class AddButton extends Component {
    state = { is_loading: false }

    componentDidMount(prevProps){ this.getIsUserSavedAlbum() }
    componentDidUpdate(prevProps){ this.getIsUserSavedAlbum() }

    getIsUserSavedAlbum = () => {
        if(this.props.hasValidToken && this.props.album.next_update < Math.round(new Date().getTime()/1000)){
            api.get(`me/albums/${this.props.spotify_id}/contains`).then(
                response => this.props.updateSpotifyAlbum(response.data)
            )
        }
    }
    handleSavedRecordClick = () => {
        if(!this.props.hasValidToken){
            this.props.toogleSpotifyModal('Add Button');
        } else {
            this.setState({ is_loading: true });
            if(this.props.album.is_saved) {
                api.delete(`me/albums/${this.props.spotify_id}`).then(
                    response => this.props.updateSpotifyAlbum(response.data)
                ).finally(() => this.setState({ is_loading: false }))
            } else {
                api.put(`me/albums/${this.props.spotify_id}`).then(
                    response => this.props.updateSpotifyAlbum(response.data)
                ).finally(() => this.setState({ is_loading: false }))
            }
        }
    };
    render() {
        return (
            <Button
                basic
                inverted
                size={ this.props.size }
                circular={ this.props.circular }
                loading={ this.state.is_loading }
                onClick={ this.handleSavedRecordClick }
                onMouseEnter={ this.props.activateHover }
                onMouseLeave={this.props.deactivateHover }
                icon={ this.props.album.is_saved ? 'heart' : 'heart outline' }
                className={`add-button ${this.props.separated ? 'separated' : ''}`}/>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        album: state.recordings.albums.find(element => element.id === ownProps.spotify_id),
        hasValidToken: state.app.cookie_expiration_time != null && state.app.cookie_expiration_time > Math.round(new Date().getTime()/1000)
    };
};

export default connect(mapStateToProps, { toogleSpotifyModal, updateSpotifyAlbum })(AddButton);
