import React, { useState } from 'react';
import { Form, Checkbox, Grid } from 'semantic-ui-react'

function ColorPicker(props) {
    const [color, setColor] = useState(null);
    const handleChange = (e, { value }) => setColor(value)
    return (
        <Form>
            <Grid className="color-picker" textAlign='center'>
                <Grid.Row className="colors" verticalAlign='middle' columns={6}>
                    <Grid.Column>
                        <Form.Field>
                            <Checkbox
                                radio
                                value='red'
                                className="color red"
                                checked={color === 'red'}
                                onChange={handleChange}/>
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field>
                            <Checkbox
                                radio
                                value='blue'
                                className="color blue"
                                checked={color === 'blue'}
                                onChange={handleChange}/>
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field>
                            <Checkbox
                                radio
                                value='green'
                                className="color green"
                                checked={color === 'green'}
                                onChange={handleChange}/>
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field>
                            <Checkbox
                                radio
                                value='yellow'
                                className="color yellow"
                                checked={color === 'yellow'}
                                onChange={handleChange}/>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    )
}

export default ColorPicker;
