import React from 'react'
import Main from '../layouts/Main';
import { Header, Grid } from 'semantic-ui-react';
import RecordingCover from '../components/RecordingCover';
import SpotifyLoginModal from '../components/spotify/LoginModal';

class Recordings extends React.Component {
    render() {
        return (
            <Main>
                <Grid columns={3} divided stackable>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign='center'><Header as='h1' inverted>Recordings</Header></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <RecordingCover
                                size='medium'
                                slug='trotm'
                                spotify_id='7qKYMvZZasUqBPsIjXLhP6'
                                label={{color: 'red', content: 'NEW', icon: 'thumbs up outline', ribbon: 'right'}}/>
                            <Header as='h3' inverted textAlign='center'>The Revolution Of The Maschinen</Header>
                            <Header as='h4' inverted textAlign='center'>2020</Header>
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            <RecordingCover
                                size='medium'
                                slug='transmissions'/>
                            <Header as='h3' inverted textAlign='center'>Transmissions EP</Header>
                            <Header as='h4' inverted textAlign='center'>2012</Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <SpotifyLoginModal section={this.props.match.url}/>
            </Main>
        )
    }
}
export default Recordings
