import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Admin from './components/Admin';
import ScrollToTop from './components/ScrollToTop';
import Application from './components/Application';
import Spotify from './components/Spotify';
import SwitchRouter from './components/SwitchRouter';

import { Provider } from "react-redux";
import store from "./redux/store/index";

import * as serviceWorker from './serviceWorker';

import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";

const flatten = require('flat');
const messages = {
    'en': flatten(messages_en),
    'es': flatten(messages_es)
};
const language = navigator.language.split(/[-_]/)[0];

const routing = (
  <Router>
    <IntlProvider locale={language} messages={messages[language]}>
        <Provider store={store}>
            <ScrollToTop>
                <Application>
                    <Spotify>
                        <Admin>
                            <SwitchRouter/>
                        </Admin>
                    </Spotify>
                </Application>
            </ScrollToTop>
        </Provider>
    </IntlProvider>
    <ToastContainer position="bottom-center" />
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
