import {
    UPDATE_SPOTIFY_ALBUM, SET_SPOTIFY_PLAYING_TRACK, SET_APP_VERSION
} from "../constants/action-types";

const initialState = {
    albums: [{
        id:'7qKYMvZZasUqBPsIjXLhP6',
        is_saved: false,
        is_playing: false,
        year: 2020,
        slug: 'trotm',
        tracks: { total: 11 },
        next_update: null,
        streaming_platforms: [
            { name: 'apple', url: '//music.apple.com/mx/album/the-revolution-of-the-maschinen/1502626160'},
            { name: 'spotify', url: '//open.spotify.com/album/7qKYMvZZasUqBPsIjXLhP6?si=6W9783RYTK6d10BAoMcTXw'},
            { name: 'youtube', url: '//www.youtube.com/playlist?list=OLAK5uy_mdqj3sujameC9DRDPHW5z01f_uzMmgGyg'},
            { name: 'amazon', url: '//music.amazon.com.mx/albums/B085VHFN16?ref=dm_sh_7oy42UFmNCig5DVYlOGZvXFOb'}
        ]
    },{
        year: 2012,
        slug: 'transmissions',
        tracks: { total: 7 },
        streaming_platforms: [
            {name: 'bandcamp', url: '//phonobox.bandcamp.com/album/transmissions-ep'}
        ]
    }],
    next_update: null,
};

function recordingsReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_SPOTIFY_ALBUM:
            return Object.assign({}, state, {
                albums: state.albums.map(item => {
                    if(item.id === action.payload.id){
                        return Object.assign({}, item, action.payload, {
                            next_update: Math.round(
                                new Date(new Date().getTime() +
                                (process.env.REACT_APP_SPOTIFY_UPDATE * 60 * 1000))/1000
                            )
                        })
                    }
                    return item;
                })
            });
        case SET_SPOTIFY_PLAYING_TRACK:
            return Object.assign({}, state, {
                albums: state.albums.map(item => {
                    if(item.id === action.payload.album_id){
                        return Object.assign({}, item, {
                            is_playing: action.payload.is_playing
                        })
                    }
                    return item;
                })
            });
        case SET_APP_VERSION:
            return initialState;
        default:
            return state
    }
}

export default recordingsReducer;
