import React from 'react';
import '../../sass/shop.scss';
import Main from '../../layouts/Main';

import ProductGrid from '../../components/store/ProductGrid';
import InterestedModal from '../../components/shop/InterestedModal';

class Index extends React.Component {
    render() {
        return (
            <Main>
                <ProductGrid/>
                <InterestedModal/>
            </Main>
        )
    }
}

export default Index;
