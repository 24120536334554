import React from 'react';
import Main from '../../../layouts/Main';
import DataTable from '../../../components/Datatable';
import { Header, Grid } from 'semantic-ui-react';

export default class Index extends React.Component {
    render() {
        return (
            <Main>
                <Grid columns={1} divided stackable>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign='center'>
                            <Header as='h1' inverted>Products</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="datatable-container">
                        <Grid.Column width={16} textAlign='center'>
                            <DataTable
                            actions
                            resource="products"
                            sorted_column="name"
                            columns={[
                                {data: "cover", type: "image", sortable: false},
                                {data: "name"},
                                {data: "sku"},
                                {data: "created_at"}
                            ]}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Main>
        )
    }
}
