import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { setAppVersion, setTokenExpirationTime } from "../redux/actions/index";
import appVersion from "../version.json";

function Application(props) {
    useEffect(() => {
        if(props.app.version !== appVersion.version){
            props.setAppVersion(appVersion.version)
        }
    });
    return <Fragment>{props.children}</Fragment>;
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        hasValidToken: state.app.token_expiration_time != null && state.app.token_expiration_time > Math.round(new Date().getTime()/1000),
        hasValidCookie: state.app.cookie_expiration_time != null && state.app.cookie_expiration_time > Math.round(new Date().getTime()/1000)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAppVersion: version => dispatch(setAppVersion(version)),
        setTokenExpirationTime: data => dispatch(setTokenExpirationTime(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Application);
