import React, {useState} from 'react';
import ImageOrientation from './ImageOrientation';
import { Segment, Image } from 'semantic-ui-react';
import api from '../../api';
import '../../sass/attachment.scss';

export default function Attachment(props) {
    let imageInput = null;
    const [isLoading, setLoading] = useState(false);
    const [imageURL, setImageURL] = useState(props.value ? props.value : `${process.env.REACT_APP_CLOUDFRONT}/images/image.png`);
    function handleClick(){
        imageInput.click();
    }
    function handleChange(e){
        let file = e.target.files[0];
        if(file) {
            new ImageOrientation(file, function(imageFile){
                setLoading(true);
                let data = new FormData();
                data.append('image', file);
                if(props.uploadToken)
                    data.append('upload_token', props.uploadToken);
                api.post(`attachments/${props.data}/${props.resource}${props.id ? '/'+props.id : ''}`, data).then(
                    response => setImageURL(response.data.url)
                ).then(() => {
                    setLoading(false);
                })
            });
        }
    }
    return (
        <Segment
            circular basic compact
            loading={isLoading}
            className="attachment">
            <Image
                circular
                onClick={handleClick}
                data-type={props.data}
                style={{backgroundImage: `url(${imageURL})`}}
                label={{ as: 'a', color: 'red', corner: 'right', icon: 'save' }}/>
            <input
                type="file"
                accept="image/*"
                className="image-form-input"
                onChange={handleChange}
                ref={(input) => { imageInput = input }}/>
        </Segment>
    );
}
