import React, { Component } from 'react'
import { Menu, Icon } from 'semantic-ui-react';
import ReactGA from 'react-ga';

const items = {
    'facebook': {'icon': 'facebook f', link: '//facebook.com/Phonobox/'},
    'twitter': {'icon': 'twitter', link: '//twitter.com/phonoboxband'},
    'instagram': {'icon': 'instagram', link: '//instagram.com/phonoboxband_/'},
    'youtube': {'icon': 'youtube', link: '//youtube.com/user/phonoboxband'}
}

export default class SocialNetworks extends Component {
    render() {
        const renderLinks = () => Object.keys(items).map(key => {
            return (
                <Menu.Item key={key} to={items[key].link} target='_blank' as={ReactGA.OutboundLink} eventLabel={`${key[0].toUpperCase() + key.slice(1)}`}>
                    <Icon name={items[key].icon} size='large'/>
                </Menu.Item>
            )
        });
        if(this.props.isSubMenu){
            return <Menu.Menu position='right' className="social-media">{renderLinks()}</Menu.Menu>
        }
        return <Menu text widths="7" className="social-media" inverted>{renderLinks()}</Menu>
    }
}
