import React from 'react'
import Main from '../layouts/Main';
import { Header } from 'semantic-ui-react';

class Notfound extends React.Component {
    render() {
        return (
            <Main>
                <Header as='h1' inverted>Not Found</Header>
            </Main>
        )
    }
}

export default Notfound
