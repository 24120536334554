import React from 'react';
import Main from '../../../layouts/Main';
import AdminForm from '../../../components/form/Main';
import api from '../../../api';

export default class Item extends React.Component {
    state = {
        isLoading: false, categories: [{key:'empty'}]
    }
    componentDidMount() {
        api.get('store/categories').then(
            response => this.setState({
                isLoading: false,
                categories: response.data.map(item => {
                    return {key: item.id, text: item.name, value: item.id}
                })
            })
        ).catch(err => console.log(err))
    }
    render() {
        return (
            <Main>
                <AdminForm
                    submit
                    resource="products"
                    fields={[
                      [
                          {"type": "attachment", "data": "cover"},
                          [
                              {"type": "text", "title": "name", "data": "name"},
                              {"type": "text", "title": "price", "data": "price"},
                              {"type": "dropdown", "title": "category", "data": "category", "options": this.state.categories}
                          ]
                      ],
                      [
                          {"type": "text", "title": "stock", "data": "stock"},
                          {"type": "text", "title": "sku", "data": "sku"}
                      ],
                      [{"type": "textarea", "title": "resume", "data": "resume"}],
                      [{"type": "textarea", "title": "content", "data": "content"}]
                  ]}>
                </AdminForm>
            </Main>
        )
    }
}
