import React, { Component } from 'react';
import { Grid, Table, Menu, Icon, Segment, Button, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import api from '../api';

export default class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entities: {
        data: [],
        meta: {
          current_page: 1,
          from: 1,
          last_page: 1,
          per_page: 5,
          to: 1,
          total: 1,
        },
      },
      first_page: 1,
      current_page: 1,
      columns: this.props.columns.map(item => item.data).join(),
      sorted_column: this.props.sorted_column ? this.props.sorted_column : (this.props.columns[0].data ? this.props.columns[0].data : this.props.columns[0].title),
      offset: 4,
      order: 'asc',
      is_loading: true,
    };
  }
  componentDidMount() {
    this.setState({ current_page: this.state.entities.meta.current_page }, () => {
        this.fetchEntities()
    });
  }
  fetchEntities() {
      this.setState({ is_loading: true });
      let fetchUrl = `${this.props.url || `admin/${this.props.resource}/datatable`}/?page=${this.state.current_page}&order_column=${this.state.sorted_column}&order=${this.state.order}&per_page=${this.state.entities.meta.per_page}`;
      api.get(fetchUrl).then(
          response => this.setState({ entities: response.data, is_loading: false }))
      .catch(err => {
          console.log(err);
      });
  }
  columnHead(value) {
    let data = value.data ? value.data : value.title;
    return data.split('_').join(' ').toUpperCase()
  }
  sortByColumn(column) {
    if(column.sortable !== false){
        if (column === this.state.sorted_column) {
          this.state.order === 'asc' ? this.setState({ order: 'desc', current_page: this.state.first_page }, () => {this.fetchEntities()}) : this.setState({ order: 'asc' }, () => {this.fetchEntities()});
        } else {
          this.setState({ sorted_column: column, order: 'asc', current_page: this.state.first_page }, () => {this.fetchEntities()});
        }
    }
  }
  tableHeads() {
      var columns = this.props.columns.map(column => {
          return (
              <Table.HeaderCell
                  className="table-head"
                  key={column.data}
                  onClick={() => {this.sortByColumn(column)}}>
                  { this.columnHead(column) }
                  { column.sortable !== false && column === this.state.sorted_column && (
                      <Icon name={this.state.order === 'asc' ? 'chevron up' : 'chevron down'}/>
                  )}
                  { column.sortable !== false && column !== this.state.sorted_column && (
                      <Icon name="sort" disabled/>
                  )}
              </Table.HeaderCell>
          )
      });
      if(this.props.actions){
          columns.push(<Table.HeaderCell className="table-head" key="action">ACTIONS</Table.HeaderCell>)
      }
      return columns
  }
  toolsMenu() {
      return (
          <Menu icon='labeled' borderless size="tiny" floated="right" inverted text>
              <Menu.Item name='add' as={Link} to={`/admin/${this.props.resource}/create`}>
                <Icon name='add'/>Add Item
              </Menu.Item>
        </Menu>
      )
  }
  renderDataColumn(value, type, column){
      switch (type) {
          case 'image':
            return <Image src={value ? value : `${process.env.REACT_APP_CLOUDFRONT}/images/image.png`} circular/>
          default:
            return value
      }
  }
  dataList() {
    if (this.state.entities.data.length) {
      return this.state.entities.data.map((item, index) => {
          return (
              <Table.Row key={ index }>
                {this.props.columns.map((column, i) => {
                    if(column.data in item)
                        if(column.render)
                            return column.render(item[column.data], column.data, item)
                        else
                            return <Table.Cell className={ column.type || 'text'} key={`${column.data}_${i}`}>{this.renderDataColumn(item[column.data], column.type, column)}</Table.Cell>
                    return null;
                })}
                {this.props.actions && (
                    <Table.Cell>
                        <Button.Group basic size='small'>
                            {(!Array.isArray(this.props.actions) || this.props.actions.includes('edit')) && (
                                <Button
                                    circular
                                    icon='pencil'
                                    as={Link} to={`/admin/${this.props.resource}/${item.id}`}/>
                            )}
                            {(!Array.isArray(this.props.actions) || this.props.actions.includes('delete')) && (
                                <Button
                                    circular
                                    icon='trash'
                                    onClick={() => {this.handleDelete(item)}}/>
                            )}
                        </Button.Group>
                    </Table.Cell>
                )}
              </Table.Row>
          )
      })
    } else {
      return (
          <Table.Row>
            <Table.Cell>No Records Found.</Table.Cell>
          </Table.Row>
      )
    }
  }
  pagesNumbers() {
    if (!this.state.entities.meta.to) {
        return [];
    }
    let from = this.state.entities.meta.current_page - this.state.offset;
    if (from < 1) {
        from = 1;
    }
    let to = from + (this.state.offset * 2);
    if (to >= this.state.entities.meta.last_page) {
        to = this.state.entities.meta.last_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
        pagesArray.push(page);
    }
    return pagesArray;
  }
  pageList() {
    return this.pagesNumbers().map(page => {
        return (
            <Menu.Item
                as='a'
                key={page}
                disabled={page === this.state.entities.meta.current_page}
                onClick={() => this.changePage(page)}>{page}</Menu.Item>
        )
    })
  }
  handleDelete(item) {
      this.setState({ is_loading: true });
      api.delete(`admin/${this.props.resource}/${item.id}`).then(response => {
          this.fetchEntities();
          toast.success('Item deleted successfully', { position: "top-right" });
      }).catch(err => {
          console.log(err);
          this.setState({ is_loading: false });
          toast.error('Something went wrong, please try again later', { position: "top-right" })
      });
  }
  changePage(pageNumber) {
    this.setState({ current_page: pageNumber }, () => {this.fetchEntities()});
  }
  render() {
    return (
        <Grid padded className="datatable-grid">
            {this.props.actions && (
                <Grid.Row className="datatable-tools">
                    <Grid.Column>{this.toolsMenu()}</Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row>
                <Grid.Column>
                    <Segment basic loading={this.state.is_loading} className="datatable">
                        <Table padded>
                            <Table.Header>
                                <Table.Row>{this.tableHeads()}</Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.dataList()}
                            </Table.Body>
                            { this.state.entities.data && this.state.entities.data.length > 0 && (
                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell>Displaying { this.state.entities.data.length } of {this.state.entities.meta.total} Records</Table.HeaderCell>
                                        <Table.HeaderCell colSpan={this.props.columns.length + (this.props.actions ? 1 : 0)}>
                                          <Menu floated='right' pagination>
                                              <Menu.Item as='a' icon
                                                  disabled={ 1 === this.state.entities.meta.current_page }
                                                   onClick={() => this.changePage(this.state.entities.meta.current_page - 1)}>
                                                  <Icon name='chevron left' />
                                              </Menu.Item>
                                              { this.pageList() }
                                              <Menu.Item as='a' icon
                                                  disabled={this.state.entities.meta.last_page === this.state.entities.meta.current_page}
                                                  onClick={() => this.changePage(this.state.entities.meta.current_page + 1)}>
                                                  <Icon name='chevron right' />
                                              </Menu.Item>
                                          </Menu>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            )}
                        </Table>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
  }
}
