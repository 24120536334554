import React from 'react';
import { Menu, Icon } from 'semantic-ui-react'
import ReactGA from 'react-ga';

export default class StreamingPlatforms extends React.Component {
    render(){
        if(this.props.platforms.length > 0)
            return(
                <Menu
                    text
                    inverted
                    widths={this.props.widths}
                    className={`streaming-platforms ${this.props.justify ? 'justify-'+this.props.justify : ''} ${this.props.removeBottomSpace ? 'remove-bottom-space' : ''}`}>
                    {
                        this.props.platforms.map((item, key) => {
                            return (
                                <Menu.Item key={key} to={item.url} target='_blank' as={ReactGA.OutboundLink} eventLabel={`${item.name[0].toUpperCase() + item.name.slice(1)} Platform`}>
                                    <Icon name={item.name} size="big"/>
                                </Menu.Item>
                            )
                        })
                    }
                </Menu>
            )
        return <p/>;
    }
}
