import React from 'react'
import Main from '../layouts/Main';
import { Header } from 'semantic-ui-react';

class Press extends React.Component {
    render() {
        return (
            <Main>
                <Header as='h1' inverted>Press</Header>
            </Main>
        )
    }
}
export default Press
