// src/js/constants/action-types.js

export const TOOGLE_SPOTIFY_MODAL = "TOOGLE_SPOTIFY_MODAL";
export const TOOGLE_SPOTIFY_DEVICES_MODAL = "TOOGLE_SPOTIFY_DEVICES_MODAL";
export const SET_SPOTIFY_TOKEN = "SET_SPOTIFY_TOKEN";
export const SPOTIFY_FOLLOWING_INTENTION = "SPOTIFY_FOLLOWING_INTENTION";
export const SET_SPOTIFY_ARTIST_FOLLOW = "SET_SPOTIFY_ARTIST_FOLLOW";
export const UPDATE_SPOTIFY_ALBUM = "UPDATE_SPOTIFY_ALBUM";
export const SET_SPOTIFY_DEVICES = "SET_SPOTIFY_DEVICES";
export const SET_SPOTIFY_PLAYING_TRACK = "SET_SPOTIFY_PLAYING_TRACK";
export const STOP_SPOTIFY_TRACK = "STOP_SPOTIFY_TRACK";
export const SET_APP_VERSION = "SET_APP_VERSION";
export const SET_APP_FINGERPRINT = "SET_APP_FINGERPRINT";
export const SET_APP_DEVICE_TOKEN = "SET_APP_DEVICE_TOKEN";
export const SET_APP_TOKEN_EXPIRATION = "SET_APP_TOKEN_EXPIRATION";
export const SET_APP_COOKIES_DISCLAIMER = "SET_APP_COOKIES_DISCLAIMER";
export const ACCOUNT_RECENTLY_CREATED = "ACCOUNT_RECENTLY_CREATED";
export const CLEAR_TOKENS = "CLEAR_TOKENS";
export const CLEAR_SPOTIFY_PLAYER = "CLEAR_SPOTIFY_PLAYER";
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";
// Shop Actions
export const TOOGLE_SHOP_INTERESTED_MODAL = "TOOGLE_SHOP_INTERESTED_MODAL";
