import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Menu, Segment, Responsive, Icon } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import SocialNetworks from './SocialNetworks'
import BandIcon from './BandIcon'

class Header extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };
    adminMenuItems = () => {
        if(this.props.user && this.props.hasValidToken && this.props.user.is_admin) {
            return (
                <Menu.Menu>
                    <Menu.Item
                        name='shop'
                        as={Link} to='/shop'
                        active={this.props.location.pathname === '/shop'}/>
                    <Menu.Item
                        name='users'
                        as={Link} to='/admin/users'
                        active={this.props.location.pathname === '/admin/users'}/>
                    <Menu.Item
                        name='subscriptions'
                        as={Link} to='/admin/subscriptions'
                        active={this.props.location.pathname === '/admin/subscriptions'}/>
                    <Menu.Item
                        name='products'
                        as={Link} to='/admin/products'
                        active={this.props.location.pathname === '/admin/products'}/>
                    <Menu.Item
                        name='orders'
                        as={Link} to='/admin/orders'
                        active={this.props.location.pathname === '/admin/orders'}/>
                </Menu.Menu>
            )
        }
    }
    render() {
        return (
            <Segment basic className="header">
                <Responsive as={Menu} minWidth={Responsive.onlyTablet.maxWidth} inverted pointing secondary>
                    <BandIcon />
                    <Menu.Item
                        name='Home'
                        as={Link} to='/'
                        active={this.props.location.pathname === '/'}/>
                    <Menu.Item
                        name='recordings'
                        as={Link} to='/recordings'
                        active={this.props.location.pathname === '/recordings'}/>
                    { this.adminMenuItems() }
                    <SocialNetworks isSubMenu />
                    <Menu.Item as={Link} to='/cart'>
                        <Icon name="cart" size='large'/>
                    </Menu.Item>
                </Responsive>
                <Responsive as={Menu} maxWidth={Responsive.onlyTablet.maxWidth} className="mobile-menu" inverted pointing secondary>
                    <Menu.Item>
                        <Icon name='bars' size='big' onClick={this.props.dimmedHandler}/>
                    </Menu.Item>
                    <BandIcon />
                </Responsive>
            </Segment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.app.user,
        hasValidToken: state.app.cookie_expiration_time != null && state.app.cookie_expiration_time > Math.round(new Date().getTime()/1000)
    };
};
export default connect(mapStateToProps, { })(withRouter(Header))
