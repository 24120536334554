import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ToastContainer from '../ToastContainer';
import { Modal, Header, Icon, Grid, Image, Divider, Loader } from 'semantic-ui-react';
import {
    setSpotifyDevices, toogleSpotifyDevicesModal, setSpotifyPlayingTrack
} from "../../redux/actions/index";
import api from '../../api';

const icons = {
    'smartphone': 'mobile',
    'computer': 'laptop'
}

class DevicesModal extends Component {
    state = { is_loading: false }

    handleOnClose = () => {
        this.props.toogleSpotifyDevicesModal();
    }
    getCurrentPlayingTrack = () => {
        api.get(`me/player/currently-playing`).then(
            response => {
                if(response.status === 200){
                    this.props.setSpotifyPlayingTrack(response.data);
                }else {
                    this.props.stopSpotifyTrack();
                }
            },
            err => console.error(err)
        )
    }
    playPendingItem = () => {
        api.put("me/player/play",{ context_uri: this.props.player.pending_uri }).then(
            response => {
                this.getCurrentPlayingTrack();
                this.props.toogleSpotifyDevicesModal();
                this.setState({ is_loading: false });
            },
            err => {
                this.props.toogleSpotifyDevicesModal();
                toast.error(<ToastContainer icon='spotify' message='Something wrong happend while connection to Spotify, please try again later.' />);
            }
        );
    }
    handleOnClick = (e) => {
        this.setState({ is_loading: true });
        api.put("me/player", { play: false, device_id: e.currentTarget.dataset.device}).then(
            response => setTimeout(this.playPendingItem, process.env.REACT_APP_SPOTIFY_TIMEOUT),
            err => {
                this.props.toogleSpotifyDevicesModal();
                toast.error(<ToastContainer icon='spotify' message='Something wrong happend while connection to Spotify, please try again later.' />);
            }
        );
    }
    devicesContainer = () => {
        if(this.props.player.devices.items && this.props.player.devices.items.length > 0) {
            return this.props.player.devices.items.map((item, key) => (
                <Grid.Row key={key} onClick={this.handleOnClick} data-device={item.id} className='device-container'>
                    <Grid.Column verticalAlign="middle">
                        <Header as='h3' inverted>
                            <Icon size='mini' name={icons[item.type.toLowerCase()]}/>
                            {item.name}
                        </Header>
                    </Grid.Column>
                </Grid.Row>
            ))
        }
        return (
            <Grid.Row>
                <Grid.Column textAlign='center'>
                <Header as='h3' inverted>
                    <Icon size='mini' name='volume off'/>
                    No Devices Found, Please open Spotify in one device
                </Header>
                </Grid.Column>
            </Grid.Row>
        )
    }
    render() {
        const devices = require('../../images/connect_devices.svg');
        return (
            <Modal
                size='mini'
                dimmer='blurring'
                className='spotify-devices-modal'
                open={this.props.player.is_devices_modal_open}
                onMount={this.handleOnMount}
                onClose={this.handleOnClose}>
                <Modal.Content>
                    <Grid className={this.state.is_loading ? 'hide' : null}>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Header inverted as='h2'>Connect to a Device</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className='devices-image-container' divided>
                            <Grid.Column>
                                <Image src={devices} size='medium' centered/>
                            </Grid.Column>
                        </Grid.Row>
                        <Divider />
                        { this.devicesContainer() }
                    </Grid>
                    <Loader size='massive' disabled={!this.state.is_loading}>Loading</Loader>
                </Modal.Content>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
  return {
      player: state.player,
      spotify: state.spotify
  };
};

export default connect(mapStateToProps, {
    setSpotifyDevices, toogleSpotifyDevicesModal, setSpotifyPlayingTrack
})(DevicesModal);
