import React from 'react'
import Main from '../layouts/Main';
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import AddButton from '../components/spotify/AddButton';
import PlayButton from '../components/player/PlayButton';
import StreamingPlatforms from '../components/StreamingPlatforms';
import SpotifyLoginModal from '../components/spotify/LoginModal';
import { Header, Grid, Image, Container } from 'semantic-ui-react';
import {
    toogleSpotifyModal, updateSpotifyAlbum, setSpotifyPlayingTrack, stopSpotifyTrack
} from "../redux/actions/index";

class Recording extends React.Component {
    render() {
        const cover = require('../images/recordings/'+this.props.match.params.record+'.jpg');
        return (
            <Main>
                <Grid className='recording-container' stackable>
                    <Grid.Row columns={2}>
                        <Grid.Column width={5}>
                            <Image src={cover} rounded centered className='cover' size='medium' />
                        </Grid.Column>
                        <Grid.Column textAlign='left' width={11} className='recording-metadata'>
                            <p className='recording-type'>ALBUM</p>
                            <Header as='h1' inverted className='recording-name'>
                                <FormattedMessage
                                    id={`recordings.${this.props.match.params.record}.title`}
                                    description="Recording Name" />
                            </Header>
                            <Container className='recording-info'>
                                <p>{`${this.props.album.year} · `}
                                    <FormattedMessage
                                        id='recordings.songs'
                                        defaultMessage='songs'
                                        description="Songs Number"
                                        values={{songs: this.props.album.tracks.total}}/>
                                </p>
                            </Container>
                            <StreamingPlatforms
                                widths={8}
                                justify='left'
                                removeBottomSpace={this.props.album.id === undefined}
                                platforms={this.props.album.streaming_platforms}/>
                            { this.props.album.id && (
                                <Container className='actions-container'>
                                    <PlayButton text separated uri={this.props.album.uri} pauseable/>
                                    <AddButton
                                        circular
                                        separated
                                        spotify_id={this.props.album.id}/>
                                </Container>
                            )}
                            <Container fluid className='recording-description'>
                                <FormattedMessage
                                    id={`recordings.${this.props.match.params.record}.description`}
                                    description="Recording description" />
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <SpotifyLoginModal section={this.props.match.url}/>
            </Main>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        spotify: state.spotify,
        album: state.recordings.albums.find(element => element.slug === ownProps.match.params.record)
    };
};
export default connect(mapStateToProps, {
    toogleSpotifyModal, updateSpotifyAlbum, setSpotifyPlayingTrack, stopSpotifyTrack
})(Recording)
