import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Image } from 'semantic-ui-react';
// import ClothesOptions from './ClothesOptions';
import InterestedOptions from './tools/Interested';
import ProductPrice from './tools/Price';

class ProductCard extends React.Component {
    render() {
        return (
            <Card className="product-card">
                <Image as={Link} to="shop/product" src={this.props.image} wrapped ui={false} />
                <Card.Content>
                    <Card.Meta>
                        <span className='category'>{this.props.category}</span>
                    </Card.Meta>
                    <Card.Header as={Link} to="shop/product">{this.props.name}</Card.Header>
                    <Card.Description>
                        <ProductPrice price={this.props.price}/>
                    </Card.Description>
                </Card.Content>
                <Card.Content className="options" extra>
                    <InterestedOptions/>
                </Card.Content>
            </Card>
        )
    }
}

export default ProductCard;
