import React, { Component } from 'react';
import api from '../../api';
import ProductCard from './product/Card';
import { Header, Grid } from 'semantic-ui-react';

export default class ProductGrid extends Component {
    constructor(props) {
      super(props);
      this.state = {
          entities: {
            data: [],
            meta: {
              current_page: 1,
              from: 1,
              last_page: 1,
              per_page: 5,
              to: 1,
              total: 1,
            },
          },
          first_page: 1,
          current_page: 1,
          offset: 4,
          order: 'asc',
          is_loading: true,
      }
    }
    componentDidMount() {
        this.setState({ current_page: this.state.entities.meta.current_page }, () => {
            this.fetchEntities()
        });
    }
    fetchEntities() {
        this.setState({ is_loading: true });
        let fetchUrl = `${this.props.url || `store/products`}/?page=${this.state.current_page}&order=${this.state.order}&per_page=${this.state.entities.meta.per_page}`;
        api.get(fetchUrl).then(
            response => this.setState({ entities: response.data, is_loading: false }))
        .catch(err => {
            console.log(err);
        });
    }
    dataList(){
        if (this.state.entities.data.length) {
            var items = [];
            while (this.state.entities.data.length) {
                items.push(this.state.entities.data.splice(0, 3));
            }
            return items.map((item, key) => <Grid.Row key={key}>{
                item.map((i,k) => <Grid.Column key={k}>
                    <ProductCard
                         id={i.id}
                         name={i.name}
                         price={i.price}
                         category="Women's T-Shirt"
                         image={i.cover}/>
                </Grid.Column>)
            }</Grid.Row>)
      }
    }
    render() {
        return (
            <Grid columns={3} divided stackable>
                <Grid.Row>
                    <Grid.Column width={16} textAlign='center'>
                        <Header as='h1' inverted>Shop</Header>
                    </Grid.Column>
                </Grid.Row>
                {this.dataList()}
            </Grid>
        )
    }
}
