import {
    SET_APP_VERSION, SET_APP_FINGERPRINT, SET_APP_DEVICE_TOKEN,
    SET_APP_COOKIES_DISCLAIMER, CLEAR_TOKENS, SET_APP_TOKEN_EXPIRATION,
    SET_USER, CLEAR_USER
} from "../constants/action-types";

const initialState = {
    user: null,
    version: null,
    fingerprint: null,
    token_expiration_time: null,
    cookie_expiration_time: null,
    show_cookies_disclaimer: true,
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case SET_APP_VERSION:
            return Object.assign({}, initialState, {
                version: action.payload
            });
        case SET_APP_FINGERPRINT:
            return Object.assign({}, state, { fingerprint: action.payload });
        case SET_APP_DEVICE_TOKEN:
            return Object.assign({}, state, {
                device_token: action.payload.device_token,
                device_expiration_time: action.payload.device_expiration_time
            });
        case SET_APP_TOKEN_EXPIRATION:
            return Object.assign({}, state, {
                token_expiration_time: action.payload.token_expiration_time,
                cookie_expiration_time: action.payload.cookie_expiration_time
            });
        case SET_APP_COOKIES_DISCLAIMER:
            return Object.assign({}, state, { show_cookies_disclaimer: action.payload });
        case CLEAR_TOKENS:
            return Object.assign({}, state, { token_expiration_time: null });
        case SET_USER:
            return Object.assign({}, state, { user: action.payload });
        case CLEAR_USER:
            return Object.assign({}, state, { user: null });
        default:
            return state
    }
}

export default appReducer;
