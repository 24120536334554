import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Header, Segment, Container } from 'semantic-ui-react'
import SocialNetworks from './SocialNetworks'
import ContactForm from './ContactForm';

export default class Footer extends Component {
  render() {
    return (
        <Segment placeholder basic className="footer">
            <Grid columns={3} stackable textAlign='center' padded>
                <Grid.Row verticalAlign='top'>
                    <Grid.Column width={5} className="abstract-about">
                        <Container textAlign='justified'>
                            <Header as='h3' inverted>About Phonobox</Header>
                            <p>Phonobox believes that the true way that one can change positively his/her own reality is through one's reality check and reinterpretation.</p>
                            <p>With this in mind, Phonobox combines music experimentation with styles such as Psychedelic, Progressive, Alternative and Electronica with lyrics inspired in philosophy, science fiction, dystopia, and psychological thrillers.</p>
                            <p>This results in an ideal experience for those who enjoy Experimental/Alternative Rock music.</p>
                            <p>Copyright ©2020 All rights reserved | <Link to='/' className='text-link'>Phonobox</Link></p>
                        </Container>
                    </Grid.Column>
                    <Grid.Column width={4} className="contact-form">
                        <ContactForm />
                        <Container textAlign='left'>
                            <p>Check out our
                                <Link to='/privacy-policy' className='text-link'> Privacy Policy </Link> and
                                <Link to='/terms-and-conditions' className='text-link'> Terms and Conditions </Link>
                            </p>
                        </Container>
                    </Grid.Column>
                    <Grid.Column>
                        <Header as='h3' inverted>Follow Us</Header>
                        <SocialNetworks />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
  }
}
