import React, { Fragment, Component } from 'react';
import { connect } from "react-redux";
import api from '../api';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';
// import LaravelEncrypter from '../LaravelEncrypter';
import {
    toogleSpotifyModal, setSpotifyToken,
    setSpotifyFollowIntention, setTokenExpirationTime,
    setUser
} from "../redux/actions/index";

class Spotify extends Component {
    componentDidMount() {
        this.parseToken();
        if(!this.props.app.user && this.props.hasValidToken){
            this.getUser();
        }
    }
    parseToken() {
        let hash = this.getHashParams();
        if(hash.token_expiration_time) {
            //Set A Follow Intention From User
            if(this.props.spotify.modalOpenedBy === 'Follow Button')
                this.props.setSpotifyFollowIntention(true);
            //Store Token Expiration Time
            this.props.setTokenExpirationTime({
                token_expiration_time: hash.token_expiration_time,
                cookie_expiration_time: hash.cookie_expiration_time
            });
            //Set Spotify Token
            this.props.setSpotifyToken({
                access_token: hash.access_token,
                expiration_time: hash.expiration_time
            });
            //Notify Analytics Events
            ReactGA.event({ category: 'Spotify', action: 'Login Successful'});
            ReactPixel.trackCustom('Spotify', {action: 'Login Successful'});
            if(hash.recently_created === "true") {
                ReactGA.event({ category: 'Spotify', action: 'Account Created'});
                ReactPixel.trackCustom('Spotify', {action: 'Account Created'});
            }
            this.props.toogleSpotifyModal();
            this.getUser();
        }
    }
    getHashParams() {
        var hashParams = {};
        var e, r = /([^&;=]+)=?([^&;]*)/g,
        q = window.location.hash.substring(1);
        e = r.exec(q)
        while (e) {
            hashParams[e[1]] = decodeURIComponent(e[2]);
            e = r.exec(q);
        }
        window.location.hash = '';
        return hashParams;
    }
    getUser() {
        //Retrieve Logged User
        api.get('me').then(response => {
            this.props.setUser(response.data);
        }).catch(err => {
            toast.error("Something wrong happened retrieving your user information, try again later.");
            this.setState({ loading: false });
        });
    }
    render() {
        return <Fragment>{this.props.children}</Fragment>;
    }
}
const mapStateToProps = state => {
  return {
      app: state.app,
      spotify: state.spotify,
      hasValidToken: state.app.cookie_expiration_time != null && state.app.cookie_expiration_time > Math.round(new Date().getTime()/1000)
  };
};
export default connect(mapStateToProps, {
    toogleSpotifyModal, setSpotifyToken, setUser,
    setSpotifyFollowIntention, setTokenExpirationTime
})(Spotify);
