import React from 'react';
import '../sass/main.scss';
import { Segment, Sidebar, Container } from 'semantic-ui-react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import HeaderSidebar from '../components/HeaderSidebar';
import CookiesMessage from '../components/CookiesMessage';
import SpotifyDevicesModal from '../components/spotify/DevicesModal';

class Main extends React.Component {
    state = { is_dimmed: false };
    IsDimmedHandler = () => {
        this.setState({ is_dimmed: !this.state.is_dimmed });
    }
    render() {
        return (
            <Sidebar.Pushable>
                <Sidebar.Pusher dimmed={this.state.is_dimmed}>
                    <Segment
                        basic
                        style={{backgroundImage: this.props.backgroundImage ? `url(${this.props.backgroundImage}` : ''}}
                        className={`main-container ${this.props.section ? this.props.section + '-background' : ''}`}>
                        <Segment basic
                            className={`main-wrapper ${this.props.shade ? 'shade ' + this.props.shade : ''}`}>
                            <Header dimmedHandler={this.IsDimmedHandler} isDimmed={this.state.is_dimmed}/>
                            <Container className="content">{this.props.children}</Container>
                        </Segment>
                    </Segment>
                    <Footer />
                </Sidebar.Pusher>
                <HeaderSidebar isDimmed={this.state.is_dimmed} dimmedHandler={this.IsDimmedHandler}/>
                <CookiesMessage />
                <SpotifyDevicesModal />
            </Sidebar.Pushable>
        )
    }
}

export default Main
