import React from 'react';
import Main from '../../../layouts/Main';
import DataTable from '../../../components/Datatable';
import { Header, Grid } from 'semantic-ui-react';

export default class Index extends React.Component {
    render() {
        return (
            <Main>
                <Grid columns={1} divided stackable>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign='center'>
                            <Header as='h1' inverted>Orders</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign='center'>
                            <DataTable url="admin/orders/datatable" columns={[
                                {"data": "number"},
                                {"data": "status"},
                                {"data": "created_at"}
                            ]}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Main>
        )
    }
}
