import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Icon, Modal, Image, Header } from 'semantic-ui-react';

class InterestedModal extends Component {
    render() {
        return (
            <Modal
                basic
                size='small'
                dimmer='blurring'
                className='thankyou-modal'
                open={this.props.modalOpen}>
                <Modal.Content>
                    <Container textAlign='center' className='avatar-container'>
                        <Header as='h1' inverted>Thank You for Subscribing!</Header>
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT}/images/avatar.jpg`} size='small' circular inline/>
                        <br/><br/>
                        <p>We will send you soon news about Phonobox to your email, also when we add new cool features to the website we will let you know.</p>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green'
                        onClick={ this.props.handleCloseModal }>
                        <Icon name='thumbs up outline' /> Yes, Sure!
                    </Button>
                </Modal.Actions>
                <Modal.Description className='privacy-container'>
                    <Link to='/privacy-policy' target='_blank'>*If you have any concern about how we treat your data you can check our privacy policy</Link>
                </Modal.Description>
            </Modal>
        )
    }
}

export default InterestedModal;
