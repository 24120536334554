import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../sections/Home';
import Recording from '../sections/Recording';
import Recordings from '../sections/Recordings';
import Press from '../sections/Press';
import ThankYou from '../sections/ThankYou';
import NotFound from '../sections/NotFound';
import Privacy from '../sections/Privacy';
import Terms from '../sections/Terms';
import Shop from '../sections/shop/Index';
import Product from '../sections/shop/Product';
import AdminUsers from '../sections/admin/users/Index';
import AdminUsersItem from '../sections/admin/users/Item';
import AdminSubscriptions from '../sections/admin/subscriptions/Index';
import AdminOrders from '../sections/admin/orders/Index';
import AdminProduct from '../sections/admin/products/Index';
import AdminProductItem from '../sections/admin/products/Item';

export default function SwitchRouter() {
    return (
        <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/recordings" component={Recordings} exact/>
            <Route path="/recordings/:record" component={Recording} />
            <Route path="/press" component={Press} />
            <Route path="/thank-you" component={ThankYou} />
            <Route path="/privacy-policy" component={Privacy} />
            <Route path="/terms-and-conditions" component={Terms} />
            <Route path="/shop" component={Shop} exact/>
            <Route path="/shop/product" component={Product} />
            <Route path="/admin/users" component={AdminUsers} exact/>
            <Route path="/admin/users/:id" component={AdminUsersItem} exact/>
            <Route path="/admin/subscriptions" component={AdminSubscriptions} exact/>
            <Route path="/admin/orders" component={AdminOrders} exact/>
            <Route path="/admin/products" component={AdminProduct} exact/>
            <Route path="/admin/products/:id" component={AdminProductItem}/>
            <Route component={NotFound} />
        </Switch>
    )
}
