import React from 'react';
import { connect } from "react-redux";
import { Button, Grid } from 'semantic-ui-react';
import SizeDropdown from "./Size";
import { toogleInterestedModal } from "../../../../redux/actions/shop";

class InterestedOptions extends React.Component {
    render() {
        return (
            <Grid className="interested" textAlign='center'>
                <Grid.Row verticalAlign='middle' columns={2}>
                    <Grid.Column width={6}>
                        <SizeDropdown compact/>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Button icon='cart' content="I'm Interested" color="red"/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
    }
};

export default connect(mapStateToProps, { toogleInterestedModal })(InterestedOptions);
