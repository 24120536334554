import React from 'react';
import api from '../../api';
import { withRouter } from 'react-router-dom';
import { Grid, Form, Loader, Segment, Dimmer, TextArea, Dropdown } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Attachment from './Attachment';
import '../../sass/form/main.scss';

class Main extends React.Component {
    state = {
        isLoading: false, isSubmitting: false, isCreated: false,
        item: null, uploadToken: null, values:{}, fields:[]
    }
    componentDidMount() {
        let { params } = this.props.match;
        if(params.id !== "create"){
            //Existing Item
            this.setState({ isLoading: true });
            api.get(`admin/${this.props.resource}/${params.id}`).then(
                response => this.setState({
                    isLoading: false,
                    item: response.data
                })
            ).catch(err => console.log(err))
        } else {
            //NewItem
            this.setState({
                uploadToken: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            });
        }
    }
    createFormInput = (item, key) => {
        if(!Array.isArray(item)){
            switch (item.type) {
                case 'attachment':
                    return <Attachment
                            key={key}
                            data={item.data}
                            resource={this.props.resource}
                            uploadToken={this.state.uploadToken}
                            id={this.state.item ? this.state.item.id : null}
                            value={this.state.item ? this.state.item[item.data] : null}/>
                case 'textarea':
                    return <Form.Field
                            key={key}
                            id={item.data}
                            name={item.data}
                            control={TextArea}
                            label={item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                            placeholder={item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                            onChange={this.handleChange}
                            defaultValue={this.state.item ? this.state.item[item.data] : ''}/>
                case 'dropdown':
                    return <Form.Field key={key}>
                                <label>{item.title.charAt(0).toUpperCase() + item.title.slice(1)}</label>
                                <Dropdown
                                    key={key}
                                    id={item.data}
                                    name={item.data}
                                    options={item.options}
                                    placeholder={item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                                    onChange={this.handleChange}
                                    search selection/>
                            </Form.Field>
                default:
                    return <Form.Input
                            key={key}
                            id={item.data}
                            name={item.data}
                            label={item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                            placeholder={item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                            onChange={this.handleChange}
                            defaultValue={this.state.item ? this.state.item[item.data] : ''}/>
            }
        } else {
            return <Form.Group widths='equal' key={key} className="sub-group">{
                item.map((i, k) => {
                    return this.createFormInput(i,k)
                })
            }
            </Form.Group>
        }
    }
    handleSubmit = (event) => {
        console.log(event.target.elements)
        // console.log(this.state.fields)
        // console.log(this.state.values)
        // console.log(this.state.values.filter((item, key) => {
        //     console.log(item,key)
        //     return false;
        // }))

        //Change Loading State
        this.setState({ isSubmitting: true });
        //Send the Request
        let values = { ...this.state.values };
        //Add Upload Token if required
        if(this.state.uploadToken !== null){
            values.uploadToken = this.state.uploadToken;
        }
        // Object.keys(values).forEach((key) => {
        //     console.log(key)
        //     console.log(this.state.fields.find(field => key == field))
        //     if(this.state.fields.indexOf(key) == -1){
        //         console.log('delete ',key)
        //         //delete values[key];
        //     }
        // });
        console.log(this.state.fields);
        console.log(this.state.values);
        //Send Request
        // api({
        //     method: this.state.item === null ? "post" : "put",
        //     url:`${process.env.REACT_APP_BACKEND_API_URL}/admin/${this.props.resource}${this.state.item !== null ? `/${this.state.item.id}`  : ''}`,
        //     data: values
        // })
        // .then(response => {
        //     this.setState({ isSubmitting: false });
        //     toast.success(this.props.intl.formatMessage({ id: `toast.${this.state.item ? "update" : "create"}`}),{
        //         onClose: () => {
        //             this.props.history.push(`/admin/${this.props.resource}`);
        //         }
        //     });
        // }).catch(err => {
        //     this.setState({ isSubmitting: false });
        //     toast.error(this.props.intl.formatMessage({ id: "toast.error"}));
        // })
        event.preventDefault();
    }
    handleChange = (e) => {
        console.log(e)
        let values = { ...this.state.values }
        values[e.target.id] = e.target.value;
        this.setState({ values: values });
    }
    render() {
        return !this.state.isLoading ? (
            <Grid columns={1} divided stackable centered>
                <Grid.Row>
                    <Grid.Column width={16} textAlign='center'>
                        {this.props.item === null && (
                            <FormattedMessage id={`admin.${this.props.resource}.create`}/>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={14}>
                        <Form
                            inverted
                            className="admin-form"
                            onSubmit={this.handleSubmit}
                            id={`backend-${this.props.resource}-form`}>
                            {this.props.fields && !this.props.fields.find(element => Array.isArray(element)) && (
                                <Form.Group widths='equal'>
                                    {this.props.fields.map((item, key) => {
                                        return this.createFormInput(item, key)
                                    })}
                                </Form.Group>
                            )}
                            {this.props.fields && this.props.fields.find(element => Array.isArray(element)) && (
                                this.props.fields.map((item, key) => {
                                    return (
                                        <Form.Group widths='equal' key={key}>
                                            {item.map((i,k) => {
                                                return this.createFormInput(i, k)
                                            })}
                                        </Form.Group>
                                    )
                                })
                            )}
                            {!this.props.fields && this.props.children}
                            { this.props.submit && (
                                <Form.Button loading={this.state.isSubmitting} floated="right">{this.state.item === null ? "Create" : "Save"}</Form.Button>
                            )}
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        ) : (
            <Segment basic className="form-loader">
                <Dimmer active>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
            </Segment>
        )
    }
}

export default injectIntl(withRouter(Main));
