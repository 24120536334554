import React from 'react'
import '../sass/home.scss';
import Main from '../layouts/Main';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Grid, Header } from 'semantic-ui-react';
import RecordingCover from '../components/RecordingCover';
import SpotifyFollowButton from '../components/spotify/FollowButton';
import SpotifyLoginModal from '../components/spotify/LoginModal';
import StreamingPlatforms from '../components/StreamingPlatforms';

class Home extends React.Component {
    render() {
        return (
            <Main section='home' shade='black' backgroundImage={`${process.env.REACT_APP_CLOUDFRONT}/images/tape.gif`}>
                <Grid columns={2} stackable>
                    <Grid.Row centered>
                        <Grid.Column verticalAlign='middle' textAlign='left' className="trotm-info">
                            <Header as='h1' inverted>
                                <FormattedMessage
                                    id={`recordings.${this.props.album.slug}.title`}
                                    defaultMessage={this.props.album.slug}
                                    description="Recording New Name" />
                            </Header>
                            <SpotifyFollowButton />
                            <p>Is now Available on all streaming and online music stores</p>
                            <StreamingPlatforms
                                widths={4}
                                justify='left'
                                removeBottomSpace={this.props.album.id === undefined}
                                platforms={this.props.album.streaming_platforms}/>
                        </Grid.Column>
                        <Grid.Column className="cover-container" textAlign='center'>
                            <RecordingCover
                                size='large'
                                slug='trotm'
                                spotify_id={this.props.album.id}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <SpotifyLoginModal section={this.props.match.url}/>
            </Main>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        album: state.recordings.albums.find(element => element.slug === 'trotm')
    };
};
export default connect(mapStateToProps)(Home);
