import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import AddButton from '../components/spotify/AddButton';
import PlayButton from '../components/player/PlayButton';
import { Image, Dimmer, Grid, Container } from 'semantic-ui-react';

class RecordingCover extends React.Component {
    constructor(props) {
        super(props);
        this.state = { active: false, is_hover: false};
    }
    //Hover Activation & Deactivation
    activateHover = () => {this.setState({ is_hover: true });}
    deactivateHover = () => {this.setState({ is_hover: false });}
    //Dimmer Activation & Deactivation
    activateDimmer = () => this.setState({ active: true });
    deactivateDimmer = () => this.setState({ active: false });
    //Container Click
    handleRecordClick = (event) => {
        if(this.state.is_hover){
            event.preventDefault()
        }
    }
    render() {
        const { active } = this.state;
        const content = (this.props.spotify_id && (
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column verticalAlign='middle'>
                        <AddButton
                            size='huge'
                            spotify_id={this.props.spotify_id}
                            activateHover={this.activateHover}
                            deactivateHover={this.deactivateHover}/>
                    </Grid.Column>
                    <Grid.Column verticalAlign='middle'>
                        <PlayButton
                            circular
                            size='big'
                            icon='play'
                            uri={this.props.album.uri}
                            activateHover={this.activateHover}
                            deactivateHover={this.deactivateHover}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        ))
        return (
            <Container as={Link} to={`/recordings/${this.props.slug}`} onClick={ this.handleRecordClick }>
                <Dimmer.Dimmable as={Image} label={this.props.label}
                    src={`${process.env.REACT_APP_CLOUDFRONT}/images/recordings/${this.props.slug}.jpg`}
                    alt={this.props.album.name}
                    dimmed={ this.props.spotify_id ? active : false }
                    dimmer={ this.props.spotify_id ? { active, content } : null }
                    onMouseEnter={this.activateDimmer}
                    onMouseLeave={this.deactivateDimmer}
                    rounded centered className='cover' size={this.props.size}/>
            </Container>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        album: state.recordings.albums.find(element => element.id === ownProps.spotify_id)
    };
};
export default connect(mapStateToProps)(RecordingCover);
