import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactPixel.init(process.env.REACT_APP_FACEBOOK_ID);

function ScrollToTop({ history, children }) {
    useEffect(() => {
        //Set the actual view
        if(!window.location.hash){
            ReactGA.pageview(history.location.pathname)
            ReactPixel.pageView();
        }
        //Listen to the History events
        const unlisten = history.listen(location => {
            window.scrollTo(0, 0);
            ReactGA.pageview(location.pathname)
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
