const ImageOrientation = function (file = null, callback) {
    if(file != null)
        this.orientation(file, (base64img, value) => {
            this.resetOrientation(base64img, value, (resetBase64Image) => {
                callback(dataURLtoFile(resetBase64Image, file.name, file.type))
            });
        });
}

ImageOrientation.prototype.orientation = function(file, callback) {
    var fileReader = new FileReader();
    fileReader.onloadend = function() {
      var base64img = "data:"+file.type+";base64," + arrayBufferToBase64(fileReader.result);
      var scanner = new DataView(fileReader.result);
      var idx = 0;
      var value = 1; // Non-rotated is the default
      if(fileReader.result.length < 2 || scanner.getUint16(idx) !== 0xFFD8) {
        // Not a JPEG
        if(callback) {
          callback(base64img, value);
        }
        return;
      }
      idx += 2;
      var maxBytes = scanner.byteLength;
      while(idx < maxBytes - 2) {
        var uint16 = scanner.getUint16(idx);
        idx += 2;
        switch(uint16) {
          case 0xFFE1: // Start of EXIF
            var exifLength = scanner.getUint16(idx);
            maxBytes = exifLength - idx;
            idx += 2;
            break;
          case 0x0112: // Orientation tag
            // Read the value, its 6 bytes further out
            // See page 102 at the following URL
            // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
            value = scanner.getUint16(idx + 6, false);
            maxBytes = 0; // Stop scanning
            break;
          // no default
        }
      }
      if(callback) {
        callback(base64img, value);
      }
    }
    fileReader.readAsArrayBuffer(file);
}

ImageOrientation.prototype.resetOrientation = (srcBase64, srcOrientation, callback) => {
    var img = new Image();

    img.onload = function() {
        var width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d");
        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
            canvas.width = height;
            canvas.height = width;
        } else {
            canvas.width = width;
            canvas.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
            case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, width, height ); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
            case 7: ctx.transform(0, -1, -1, 0, height , width); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
            // no default
        }
        // draw image
        ctx.drawImage(img, 0, 0);
        // export base64
        callback(canvas.toDataURL());
    };
    img.src = srcBase64;
}

const dataURLtoFile = function (dataurl, filename) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

const arrayBufferToBase64 = function ( buffer ) {
  var binary = ''
  var bytes = new Uint8Array( buffer )
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] )
  }
  return window.btoa( binary );
}

export default ImageOrientation;
