import axios from 'axios';
import store from "./redux/store/index";
import { logout, setTokenExpirationTime } from "./redux/actions/index";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL
});

api.interceptors.request.use(
    config => {
        config.headers.Accept = 'application/json';
        config.withCredentials = true;
        return config;
    }
);

api.interceptors.response.use(
    response => response,
    error => {
        if (error.config && error.response && error.response.status === 401) {
            const originalRequest = error.config;
            return fetch(`${process.env.REACT_APP_BACKEND_API_URL}/auth/refresh`,{
                method: 'POST',
                credentials: 'include'
            }).then(response => {
                if(response.ok){
                    response.json().then(data => store.dispatch(setTokenExpirationTime({
                        token_expiration_time: data.token_expiration_time,
                        cookie_expiration_time: data.cookie_expiration_time
                    })));
                    originalRequest._retry = true;
                    return axios(originalRequest);
                }
                store.dispatch(logout());
            });
        }
        return Promise.reject(error);
    }
);

export default api
