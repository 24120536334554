import React from 'react';
import '../../sass/shop.scss';
import Main from '../../layouts/Main';
import ProductDetail from '../../components/store/product/Detail';

class Product extends React.Component {
    render() {
        return (
            <Main>
                <ProductDetail title="Cotton Lace Blouse"/>
            </Main>
        )
    }
}

export default Product;
