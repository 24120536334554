import React, { Fragment, Component } from 'react';
import api from '../api';
import { toast } from 'react-toastify';
import { setUser, clearUser } from "../redux/actions/index";
import { connect } from "react-redux";

class Admin extends Component {
    componentDidMount() {
        if(this.props.hasValidToken === true){
            //Retrieve Logged User
            api.get('me').then(response => this.props.setUser(response.data))
            .catch(err => {
                toast.error("Something wrong happened retrieving your user information, try again later.");
                this.props.clearUser()
            });
        }
    }
    render() {
        return <Fragment>{this.props.children}</Fragment>;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.app.user,
        hasValidToken: state.app.cookie_expiration_time != null && state.app.cookie_expiration_time > Math.round(new Date().getTime()/1000)
    };
};

export default connect(mapStateToProps, { setUser, clearUser })(Admin);
